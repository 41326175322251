import React from "react";
import dataForRtc from "../../../information/RTC/dataForRtc"
import './Choose.scss';

const CaseComponent = ({ data }) => {
  const { title, description, image } = data;
  return (
    <div className="choose-case">
      <div className="title-container">
        <div className="img-container">
          <img src={image} alt="web development icon" />
        </div>
        <h3 className="title">{title}</h3>
      </div>
      <div className="description">{description}</div>
    </div>
  );
}

export default function Choose() {
  const { title, description, cases } = dataForRtc.choose;
  return (
    <div className="choose-rtc-wrapper">
      <div className="choose-content">
        <div className="choose-text">
          <h2 className="choose-title">{title}</h2>
          <div className="choose-description">{description}</div>
        </div>
        <div className="choose-cases">
          {cases.map((caseData) => <CaseComponent data={caseData} />)}
        </div>
      </div>
    </div>
  )
}