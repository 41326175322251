import React from "react";
import dataForRtc from "../../../information/RTC/dataForRtc"
import './Solutions.scss';

const CaseComponent = ({ data }) => {
  const { title, description, image} = data;
  return (
    <div className="solutions-rtc-case">
      <div className="title-container">
        <div className="img-container">
          <img src={image} alt="web development icon" />
        </div>
        <h3 className="title">{title}</h3>
      </div>
      <div className="description">{description}</div>
    </div>
  );
}

export default function Solutions() {
  const { title, description, cases } = dataForRtc.discover;
  return (
    <div className="solutions-rtc-wrapper">
      <div className="solutions-rtc-text">
        <h2 className="solutions-title">{title}</h2>
        <div className="solutions-description">{description}</div>
      </div>
      <div className="solutions-rtc-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </div>
  );
};
