import React from "react"
import { Link } from "react-scroll" 
import { ChattingRobots } from "../../..//images/RTC/Graphics"
import dataForRtc from "../../../information/RTC/dataForRtc"

import "./Banner.scss"

export default function Banner() {
  const { banner } = dataForRtc;
  return (
     <section className="banner-parent">
      <div className="banner-child">
        <div className="banner-text-container">
          <h1 className="banner-title">{banner.title}</h1>
          <h2 className="banner-subtitle">Secure, high-performance, real-time communication adjusted to your needs. <br /> From video calls to messaging, we build solutions that work seamlessly across platforms.</h2>
          <div className="banner-description">{banner.description}</div>
          <div className="banner-btns-container">
            <Link
              to="contact-us-form"
              spy={true}
              smooth={"easeOutSine"}
              offset={-40}
              duration={1000}
            >
            <button className="banner-contact-btn">
              Contact us
            </button>
          </Link>
        </div>
        </div>
        <div className="banner-img-container">
          <ChattingRobots style="banner-img"/>
        </div>
      </div>
    </section>
  )
}
