import React from "react";
import './Recognizing.scss';

import dataForRtc from "../../../information/RTC/dataForRtc"

export default function Recognizing() {
  const {
    title,
    HighDefinition,
    PhoneBrowser,
    MessagingFile,
    MobileToMobile
  } = dataForRtc.solutions;
  return (
    <section className="recognizing-rtc-wrapper">
      <div className="recognizing-rtc-text">
        <h2 className="recognizing-title">{title}</h2>
        <div className="recognizing-description">
          Our cloud development offerings include customized WebRTC development solutions 
          for web and mobile applications. We create safe, high-level communication solutions 
          that provide real-time sharing of audio, video, and data, granting your company <br className="brake-line" />
          the capability for unbreakable connectivity.
        </div>
      </div>
      <div className="recognizing-rtc-cases">
        <div className="recognizing-rtc-case">
          <div className="title-container">
            <div className="img-container">
              <img src={HighDefinition} alt="web development icon" />
            </div>
            <h3 className="title">High-Definition Video Communication</h3>
          </div>
          <div className="description">
            Our experienced team can help you implement secure audio/video streaming across multiple browsers. The technologies we use are available on all modern browsers as well as on native clients for all major platforms.
          </div>
        </div>

        <div className="recognizing-rtc-case">
          <div className="title-container">
            <div className="img-container">
              <img src={PhoneBrowser} alt="web development icon" />
            </div>
            <h3 className="title">Phone-to-Browser <br /> Connections</h3>
          </div>
          <div className="description">
            At OS-System, we can create connections between the Public Switched Telephone Network and browsers. We are using modern technologies and best practices to deliver the best quality guaranteed.
          </div>
        </div>

        <div className="recognizing-rtc-case">
          <div className="title-container">
            <div className="img-container">
              <img src={MessagingFile} alt="web development icon" />
            </div>
            <h3 className="title">Messaging and File Sharing</h3>
          </div>
          <div className="description">
            We build secure connections that enable real-time messaging and efficient file sharing. Our WebRTC development solutions eliminate the need for cloud or data center storage by delivering data directly between devices, ensuring privacy and reducing latency.
          </div>
        </div>

        <div className="recognizing-rtc-case">
          <div className="title-container">
            <div className="img-container">
              <img src={MobileToMobile} alt="web development icon" />
            </div>
            <h3 className="title">Mobile-to-Mobile Connections</h3>
          </div>
          <div className="description">
            Our WebRTC app development services are not limited to running on desktop web browsers. We can develop modern solutions for iOS and Android devices based on our <a target="_blank" href="https://os-system.com/expertise/cross-platform/">strong cross-platform development experience.</a>
          </div>
        </div>
      </div>
    </section>
  );
}