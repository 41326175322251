import React from "react";
import './Necessary.scss';

import dataForRtc from "../../../information/RTC/dataForRtc"

const CaseComponent = ({ data }) => {
  const { title, description, image } = data;
  return (
    <div className="necessary-case">
      <div className="case-img-container">
        <img src={image} alt="web development icon" />
      </div>
      <div className="case-text">
        <h3 className="case-title">{title}</h3>
        <div className="case-description">{description}</div>
      </div>
    </div>
  );
};

export default function Necessary() {
  const { title, description, cases } = dataForRtc.features;
  return (
    <section className="necessary-rtc-wrapper">
      <div className="necessary-text">
        <h2 className="necessary-title">{title}</h2>
        <div className="necessary-description">{description}</div>
      </div>
      <div className="necessary-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </section>
  );
};
