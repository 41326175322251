import OgImage from '../../images/RTC/og_image.png';

import HighDefinition from '../../images/RTC/Solutions/streaming.svg';
import PhoneBrowser from '../../images/RTC/Solutions/sync.svg';
import MessagingFile from '../../images/RTC/Solutions/email.svg';
import MobileToMobile from '../../images/RTC/Solutions/transfer.svg';

import RealTime from '../../images/RTC/Features/clock.svg'
import EndToEnd from '../../images/RTC/Features/lock.svg'
import AdaptiveBitrate from '../../images/RTC/Features/video.svg';
import InteractiveFeatures from '../../images/RTC/Features/online.svg'
import MultiPlatform from '../../images/RTC/Features/design.svg'
import CustomizableUser from '../../images/RTC/Features/search.svg'
import ScalableInfrustructure from '../../images/RTC/Features/gear.svg'
import StrongAnalytics from '../../images/RTC/Features/chart.svg'

import SolutionsForIndustries from '../../images/RTC/Discover/helping.svg'
import Healtcare from '../../images/RTC/Discover/healthcare.svg'
import Education from '../../images/RTC/Discover/education.svg'
import Finance from '../../images/RTC/Discover/bank.svg'
import CustomerSupport from '../../images/RTC/Discover/fluent.svg'
import Ecommerce from '../../images/RTC/Discover/retail.svg'
import LogisticsTransportation from '../../images/RTC/Discover/logistic.svg'

import ExpertiseWebrtc from '../../images/RTC/Choose/ph_microphone.svg'
import SecureCompliant from '../../images/RTC/Choose/gala_secure.svg'
import CrossPlatform from '../../images/RTC/Choose/ph_device-rotate.svg'
import CustomizationIntegration from '../../images/RTC/Choose/streamline_module-puzzle.svg'

import RequirementAnalysis from '../../images/RTC/Keys/01.svg'
import ArchitectureDesign from '../../images/RTC/Keys/02.svg'
import DevelopmentImplementation from '../../images/RTC/Keys/03.svg'
import TestingQuality from '../../images/RTC/Keys/04.svg'
import DeploymentIntegration from '../../images/RTC/Keys/05.svg'

import Twillio from '../../images/RTC/Tech/twillio.svg'
import Webrtc from '../../images/RTC/Tech/webrtc.svg'
import Kurento from '../../images/RTC/Tech/kurento.svg'
import Webpack from '../../images/RTC/Tech/webpack.svg'
import Websocket from '../../images/RTC/Tech/websocket.svg'
import XMPP from '../../images/RTC/Tech/xmpp.svg'
import AWS from '../../images/RTC/Tech/aws.svg'
import Google from '../../images/RTC/Tech/google.svg'
import PHP from '../../images/RTC/Tech/php.svg'
import JAVA from '../../images/RTC/Tech/java.svg'
import NodeJs from '../../images/RTC/Tech/nodejs.svg'
import ReactNative from '../../images/RTC/Tech/react-native.svg'
import JS from '../../images/RTC/Tech/javascript.svg'
import HTML from '../../images/RTC/Tech/html.svg'
import CSS from '../../images/RTC/Tech/css.svg'
import Mongo from '../../images/RTC/Tech/mongodb.svg'
import MSSQL from '../../images/RTC/Tech/microsoft-sql.svg'
import Jquery from '../../images/RTC/Tech/jquery.svg'
import MySql from '../../images/RTC/Tech/mysql.svg'

const dataForRtc = {
  ogImage: OgImage,
  service: "rtc",
  banner: {
    title: "WebRTC App Development Services",
    description: "Let’s build something great together!",
  },
  solutions: {
    title: "Custom Web & Mobile WebRTC Solutions",
    HighDefinition,
    PhoneBrowser,
    MessagingFile,
    MobileToMobile,
  },
  features: {
    title: "Necessary Features For Your Needs",
    description: "Our WebRTC application development services are designed to fit your unique needs. The OS-System team creates feature-rich applications that improve performance, security, and end-user experience. We adjust each solution to fit your business goals, offering flexibility, scalability, and seamless integration with your existing systems.",
    cases: [
      {
        image: RealTime,
        title: "Real-Time Data Synchronization",
        description: "Provide all users with the latest information simultaneously through real-time data synchronization. This feature is essential to collaboration software, online gaming, and live streaming. Our solution offers low latency and high reliability, allowing synchronized actions across devices regardless of geographic location."   
      },
      {
        image: EndToEnd,
        title: "End-to-End Encryption",
        description: "Protect sensitive data with ultra-strong end-to-end encryption. Our offerings meet current security standards, shielding your communication from unauthorized consumers. We make use of protocols like DTLS and SRTP to encrypt the media streams and signaling. Hence, the intended recipients can only access the content."   
      },
      {
        image: AdaptiveBitrate,
        title: "Adaptive Bitrate Streaming",
        description: "Stream high-quality, uninterrupted video with adaptive bitrate streaming. This technology adjusts video quality dynamically based on network conditions to provide optimal performance. By dynamically switching between different video qualities, it provides a smooth viewing experience, even under fluctuating network conditions."   
      },
      {
        image: InteractiveFeatures,
        title: "Interactive Features",
        description: "Improve user interaction with screen sharing, virtual whiteboards, and live polls. These are learning and collaborative-friendly. Our WebRTC development solutions support multi-party interactions, which allow users to collaborate in real-time without any performance degradation."   
      },
      {
        image: MultiPlatform,
        title: "Multi-Platform Compatibility",
        description: "Our WebRTC development solutions are web, mobile, and desktop platform-supported, offering users a uniform experience across operating systems and devices. Our applications run seamlessly on browsers like Chrome, Firefox, and Safari, as well as on iOS and Android platforms"   
      },
      {
        image: CustomizableUser,
        title: "Customizable User Interfaces",
        description: "We design friendly, brandable interfaces that are highly customizable and align with your brand personality to optimize user experience. Our designers collaborate with customers to create usable layouts to improve user interaction without compromising functionality for various screen resolutions and sizes."   
      },
      {
        image: ScalableInfrustructure,
        title: "Scalable Infrastructure",
        description: "Support growing user bases with scalable infrastructure. We can easily scale up our cloud offerings as your business needs evolve. We utilize microservices architecture and containerization technologies like Docker and Kubernetes to enable smooth scaling and resource utilization."   
      },
      {
        image: StrongAnalytics,
        title: "Strong Analytics and Reporting",
        description: "Understand user actions with built-in reporting and analytical capabilities. Utilize the lessons learned to refine performance and the user experience. Our analytical features track performance levels, such as the length of calls, quality of connectivity, and trends in user actions."   
      },
    ],
  },
  discover: {
    title: "Discover How WebRTC Solutions Can Boost Your Business",
    description: "As a rule, we don’t limit the range of industries we work with. We have many projects in e-commerce, healthcare, telecom, AI, business process automation, etc. We are proud that our customers are returning to us every time they need development or enhancement of their products.",
    cases: [
      {
        image: SolutionsForIndustries,
        title: "Solutions for Industries",
        description: "WebRTC technology is transforming industries by enabling real-time communication without glitches. OS-System provides WebRTC development solutions for various industries. Our expertise ensures that your industry-specific needs are met with high-performance, scalable, and secure communications solutions."   
      },
      {
        image: Healtcare,
        title: "Healthcare",
        description: "Enable secure telemedicine consultations, remote patient monitoring, and real-time interprofessional collaboration. Our WebRTC solutions support full industry compliance with standards such as HIPAA, ensuring patient data security and confidentiality. Equipped with high-definition video, low-latency connections, and auto-switching device support, healthcare professionals can make remote diagnoses, facilitate virtual follow-ups, and improve accessibility for patients in rural areas."   
      },
      {
        image: Education,
        title: "Education",
        description: "Facilitate online learning with interactive classrooms, video conferencing, and real-time collaboration capabilities. Our WebRTC solutions facilitate seamless virtual education experiences with screen sharing, whiteboarding, and messaging. Our solutions provide educators with the ability to engage with students via real-time file sharing, group collaboration, and discussions while preserving low-latency, high-quality video streams that deliver an immersive learning experience."   
      },
      {
        image: Finance,
        title: "Finance",
        description: "Improve customer engagement with secure, real-time video consultations and distance advisory services. Our solutions provide encrypted communication for banks and financial institutions to provide security standards such as GDPR and PCI-DSS compliance. With WebRTC technology, financial planners can conduct secure consultations, verify identities, and build stronger relationships with customers while maintaining confidentiality and data integrity."   
      },
      {
        image: CustomerSupport,
        title: "Customer Support",
        description: "Improve customer experience by integrating WebRTC-powered live chat and video calling support into your business. Provide real-time, face-to-face assistance, reducing response times and improving customer satisfaction. Our solutions enable customer care agents to diagnose problems visually, guide customers through problem-solving, and offer personalized support."   
      },
      {
        image: Ecommerce,
        title: "E-commerce",
        description: "Boost sales and customer trust with live video assistance and live product demonstrations. Our technology allows businesses to create tailored shopping experiences with video chat, co-browsing functionality, and live sessions with customer service specialists. Customers can have expert advice, see products in action, and make informed buying decisions."   
      },
      {
        image: LogisticsTransportation,
        title: "Logistics & Transportation",
        description: "Facilitate simpler communication between drivers, dispatchers, and customers through video conferencing and real-time tracking. Our WebRTC-based solutions make for instant updates, bypassing delays and streamlining operations. Logistics companies can handle deliveries, provide live updates of shipments, and debug in real-time, reducing friction and increasing customer satisfaction"   
      },
    ],
  },
  choose: {
    title: "Why you should choose us",
    description: "Our professionals provide extensive experience, technical competence, and commitment to delivering secure and scalable solutions. Here's why businesses trust our WebRTC development company.",
    cases: [
      {
        image: ExpertiseWebrtc,
        title: "Expertise in WebRTC Development",
        description: "We are a team of seasoned developers with years of experience in creating real-time communication solutions. We utilize the latest WebRTC technologies to create stable, low-latency applications with high-quality audio, video, and data transmission."
      },
      {
        image: SecureCompliant,
        title: "Secure and Compliant Solutions",
        description: "Security is our top priority. We implement end-to-end encryption, authentication protocols, and industry compliance requirements such as GDPR and HIPAA to protect sensitive data. We ensure the highest level of security for our WebRTC solutions."
      },
      {
        image: CrossPlatform,
        title: "Cross-Platform Compatibility",
        description: "Our WebRTC solutions support all major browsers and devices, including desktops, mobile phones, and tablets. We offer easy integration with existing systems and also extend native app support for iOS and Android platforms."
      },
      {
        image: CustomizationIntegration,
        title: "Customization and Integration",
        description: "We tailor WebRTC solutions to your business needs. Whether you require more advanced analytics, AI-powered functionality, or compatibility with third-party services, we mold the technology to fit your workflow for optimal productivity."
      },
    ]
  },
  steps: {
    title: "Steps of WebRTC Development Services",
    description: "Our WebRTC app development company possesses a systematic and effective process of WebRTC development that ensures seamless integration and optimal performance. Below are the main steps in our process",
    cases: [
      {
        image: RequirementAnalysis,
        number: "01",
        title: "Requirement Analysis",
        description: "Our WebRTC mobile app development services begin with understanding your business needs, target market, and technical specifications. Our experts collaborate with you to define the scope, features, and functionalities your WebRTC solution should have."
      },
      {
        image: ArchitectureDesign,
        number: "02",
        title: "Architecture and Design",
        description: "We build a very secure and scalable infrastructure with the best performance and no issues integrating it into your systems. We design complex wireframes and prototypes for previewing the user experience prior to actually developing."
      },
      {
        image: DevelopmentImplementation,
        number: "03",
        title: "Development and Implementation",
        description: "We integrate contemporary WebRTC solutions to build your solution and take advantage of advanced real-time communications features like video calls, voice calls, messaging, and file transfer. Our experienced experts use agile methods to deliver responsive and effective developments."
      },
      {
        image: TestingQuality,
        number: "04",
        title: "Testing and Quality Assurance",
        description: "We thoroughly test to guarantee your WebRTC solution's reliability, security, and performance. We carry out functional testing, compatibility tests, and stress testing on diversified devices and browsers."
      },
      {
        image: DeploymentIntegration,
        number: "05",
        title: "Deployment and Integration",
        description: "After testing, we deploy the WebRTC solution inside your production environment. Our skilled developers integrate with your existing infrastructure and third-party software and provide minimal compromise on performance and security."
      },
    ]
  },
  tech: {
    title: "Our Team's Expertise",
    images:[
      Twillio, Webrtc, Kurento, Webpack, Websocket, XMPP,
      AWS, Google, PHP, JAVA, NodeJs, ReactNative, JS,
      HTML, CSS, Mongo, MSSQL, Jquery, MySql
    ],
  },
}

export default dataForRtc
