import React from "react";
import dataForRtc from "../../../information/RTC/dataForRtc"
import './Technologies.scss';

const Technologies = () => {
  const { title, images } = dataForRtc.tech;
  return (
    <div className="tech-rtc-wrapper">
      <div className="tech-content">
        <h2 className="title">
          {title}
        </h2>
        <div className="images-wrapper">
          {images.map((item) => (<div className="image-container">
            <img src={item}/>
          </div>))}
        </div>
      </div>
    </div>
  );
};

export default Technologies;